<template>
  <div class="tw-flex tw-justify-center tw-items-center md:tw-justify-end">
    <div class="tw-flex tw-items-center tw-text-gray-800">
      <VueMonthlyPicker
        v-if="currentView === 'resourceTimelineMonth'"
        class="wallchart-monthly-picker"
        :value="startDate"
        :alignment="'center'"
        :clear-option="false"
        :input-class="'button'"
        :date-format="'MMMM YYYY'"
        :selected-background-color="'#01ABF8'"
        :month-labels="monthLabels"
        data-cy="wallchart-month-picker"
        @input="changeDate"
      ></VueMonthlyPicker>

      <SingleDatePicker
        v-if="currentView !== 'resourceTimelineMonth'"
        readonly="readonly"
        :value="startDate"
        data-cy="wallchart-date-picker"
        emits-moment
        @input="changeDate"
      >
        <template v-slot>
          <label
            class="tw-pr-3 tw-self-center tw-text-base tw-font-normal tw-text-center tw-text-gray-800 tw-uppercase tw-cursor-pointer hover:tw-underline"
            >{{ title }}</label
          >
        </template>
      </SingleDatePicker>
    </div>
    <div class="tw-flex tw-items-center tw-shadow-lg tw-rounded-lg">
      <button
        v-support-touch
        class="btn tw-p-0 tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-rounded-r-none hover:tw-bg-blue-400"
        type="button"
        data-cy="timeline-prev-button"
        @click="$emit('prev')"
      >
        <SvgIcon name="cheveron-left" class="tw-w-5 tw-h-5 tw-text-gray-800" />
      </button>
      <button
        v-support-touch
        class="btn tw-p-0 tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-rounded-l-none hover:tw-bg-blue-400"
        type="button"
        data-cy="timeline-next-button"
        @click="$emit('next')"
      >
        <SvgIcon name="cheveron-right" class="tw-w-5 tw-h-5 tw-text-gray-800" />
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import MomentPeriod from '../../mixins/MomentPeriod'
import Mousetrap from 'mousetrap'
import VueMonthlyPicker from 'vue-monthly-picker'
import FormatDate from '../../mixins/FormatDate'
import SingleDatePicker from '@/components/SingleDatePicker'

export default {
  name: 'TimelineViewController',

  components: {
    SingleDatePicker,
    VueMonthlyPicker,
  },

  mixins: [MomentPeriod, FormatDate],

  props: {
    currentDateRange: {
      type: Object,
      required: true,
    },

    currentView: {
      type: String,
      required: true,
    },
  },

  computed: {
    startDate() {
      return this.currentDateRange.start
    },

    endDate() {
      return this.currentDateRange.end
    },

    title() {
      if (this.currentView === 'resourceTimelineDay') {
        return this.dayTimelineTitle
      }

      if (this.currentView === 'resourceTimelineWeek') {
        return this.dateRangeTimelineTitle
      }

      if (this.currentView === 'resourceTimeline30Days') {
        return this.dateRangeTimelineTitle
      }

      return this.startDate.format('MMM YYYY')
    },

    dayTimelineTitle() {
      const dateFormatOptionMapper = {
        dmy: 'dddd, D MMM YYYY',
        mdy: 'dddd, MMM D YYYY',
        ymd: 'dddd, YYYY MMM D',
      }

      return this.startDate.format(
        dateFormatOptionMapper[this.authUser.settings.date_format]
      )
    },

    dateRangeTimelineTitle() {
      const dateFormatOptionMapper = {
        dmy: 'D MMM YYYY',
        mdy: 'MMM D YYYY',
        ymd: 'YYYY MMM D',
      }

      const dateFormat =
        dateFormatOptionMapper[this.authUser.settings.date_format]

      return `${this.startDate.format(dateFormat)} - ${this.endDate
        .subtract(1, 'day')
        .format(dateFormat)}`
    },

    monthLabels() {
      const months = this.createPeriod(
        moment().startOf('year'),
        moment().endOf('year'),
        'months'
      )

      return months.map(month => {
        return month.format('MMM')
      })
    },
  },

  mounted() {
    Mousetrap.bind('left', e => {
      e.preventDefault()
      this.$emit('prev')
    })

    Mousetrap.bind('right', e => {
      e.preventDefault()
      this.$emit('next')
    })

    this.$once('hook:beforeDestroy', () => {
      Mousetrap.unbind('right')
      Mousetrap.unbind('left')
    })
  },

  methods: {
    changeDate(date) {
      this.$emit('date-changed', date.utc(true).format())
    },
  },
}
</script>
