<template>
  <form class="tw-px-8 tw-pt-6 tw-pb-8" @submit.prevent="submit">
    <div v-if="pickStartDate" class="tw-mb-4">
      <label class="form-label">
        Year start date <span class="required-field">&#42;</span>
      </label>

      <SingleDatePicker
        id="start_date"
        v-model="form.company.startDate"
        v-validate.disable="dateRules"
        :format="dateFormatWithSlash"
        :placeholder="dateFormatWithSlash"
        data-vv-as="calendar date"
        name="start_date"
        class="form-control"
        type="text"
        autocomplete="off"
        data-cy="start-date"
        readonly
      />

      <p
        v-show="errors.has('start_date')"
        class="tw-mt-1 tw-text-red-500 tw-text-sm tw-italic"
      >
        {{ errors.first('start_date') }}
      </p>
    </div>

    <div class="tw-mb-4">
      <label class="form-label" for="name">
        Your company name <span class="required-field">&#42;</span>
      </label>

      <input
        id="name"
        v-model="form.company.name"
        v-validate.disable="'required|max:100'"
        v-focus
        name="name"
        data-vv-as="Company Name"
        data-cy="company-name"
        class="form-control"
        autocomplete="off"
        type="text"
        placeholder="Company name"
      />

      <p v-show="errors.has('name')" class="tw-mt-1 tw-text-red-700 tw-text-sm">
        {{ errors.first('name') }}
      </p>
    </div>

    <div class="tw-mb-4">
      <label class="form-label" for="annual-leave-allowance">
        Annual leave allowance <span class="required-field">&#42;</span>
      </label>

      <div
        class="tw-flex tw-justify-between tw-items-center tw-border tw-border-gray-300 tw-rounded-lg"
      >
        <input
          id="annual-leave-allowance"
          v-model="form.company.annualLeaveAllowance"
          v-validate.disable="'required'"
          name="annualLeaveAllowance"
          data-vv-as="Annual Leave Allowance"
          data-cy="annual-leave-allowance"
          class="form-control tw-w-1/3 tw-border-0"
          min="0"
          step="any"
          type="number"
        />

        <span
          class="tw-py-3 tw-w-2/3 tw-px-4 tw-border-l tw-border-gray-300 tw-bg-gray-200 tw-rounded-r-lg"
        >
          Days per year
        </span>
      </div>

      <p
        v-show="errors.has('annualLeaveAllowance')"
        class="tw-mt-1 tw-text-red-500 tw-text-sm tw-italic"
      >
        {{ errors.first('annualLeaveAllowance') }}
      </p>
    </div>

    <div class="form-group">
      <div class="tw-w-full">
        <label class="form-label">
          Carry Over
        </label>

        <div class="tw-flex tw-pt-3 tw-justify-start tw-items-center">
          <ToggleButton
            id="carry-over"
            :value="false"
            :labels="true"
            data-cy="carry-over-switch"
            @change="enableCarryOver"
          />

          <label class="tw-ml-8 tw-text-gray-700">
            Unused allowance carries forward to next year
          </label>
        </div>
      </div>
    </div>

    <div v-if="form.hasCarryOver" class="form-group">
      <div
        class="sm:tw-flex tw-w-full md:tw-space-x-4 tw-justify-between tw-items-center tw-px-3 tw-py-3"
      >
        <label
          class="tw-flex-1 tw-items-center tw-text-gray-700"
          for="default-amount"
        >
          Maximum carry over
        </label>

        <div class="tw-flex-1 tw-mt-2 sm:tw-mt-0">
          <div
            class="tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-lg"
            style="max-height: 31px;"
          >
            <input
              id="default-amount"
              v-model="form.maximumCarryOverInDays"
              v-validate.disable="'required|decimal|min_value:1'"
              data-vv-name="default-amount"
              data-vv-as="default amount"
              data-cy="default-amount"
              class="form-control tw-px-3 tw-py-2 tw-w-1/3 tw-border-0"
              type="number"
              min="0"
              step="any"
              inputmode="decimal"
            />

            <div
              class="tw-py-2 tw-w-2/3 tw-px-4 tw-border-l tw-border-gray-300 tw-bg-gray-200 tw-rounded-r-lg"
            >
              Days
            </div>
          </div>

          <span
            v-show="errors.has('default-amount')"
            class="tw-mt-1 tw-text-red-500 tw-text-sm tw-italic"
          >
            {{ errors.first('default-amount') }}
          </span>
        </div>
      </div>

      <div
        class="sm:tw-flex tw-w-full md:tw-space-x-4  tw-justify-between tw-items-center tw-px-3 tw-py-3"
      >
        <label
          class="tw-flex-1 tw-items-center tw-text-gray-700"
          for="expiry-period"
        >
          Carry over expires after
        </label>

        <div class="tw-flex-1 tw-mt-2 sm:tw-mt-0">
          <div
            class="tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-lg tw-border-r-0"
            style="max-height: 31px;"
          >
            <input
              id="expiry-period"
              v-model="form.expiryPeriod"
              v-validate.disable="`${expiryPeriodValidation}`"
              data-vv-name="default-expiry-period"
              data-vv-as="default expiry period"
              data-cy="default-expiry-period"
              :disabled="form.expiryUnit.key === 'never_expire'"
              class="form-control tw-px-3 tw-py-2 tw-w-1/3 tw-border-0"
              type="number"
              inputmode="numeric"
            />

            <div
              class="tw-w-2/3 carry-over-policy-group policy-expiry-unit-group"
            >
              <VSelect
                id="policyExpiryUnit"
                v-model="form.expiryUnit"
                :options="selectablePolicyExpiryUnits"
                :multiple="false"
                :searchable="false"
                :show-labels="false"
                :allow-empty="false"
                track-by="key"
                label="name"
                data-cy="policy-expiry-unit"
                placeholder=""
              >
              </VSelect>
            </div>
          </div>

          <span
            v-show="errors.has('default-expiry-period')"
            class="tw-mt-1 tw-text-red-500 tw-text-sm tw-italic"
          >
            {{ expiryPeriodErrorMessage }}
          </span>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-justify-end tw-items-center tw-mt-5">
      <SpinnerButton
        :disabled="loading"
        :loading="loading"
        :spinner-only="true"
        type="submit"
        data-cy="btn-submit-companyname"
      >
        {{ submitButtonText }}
      </SpinnerButton>
    </div>
  </form>
</template>

<script>
import SpinnerButton from '@/components/SpinnerButton'
import SingleDatePicker from '@/components/SingleDatePicker'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import VSelect from 'vue-multiselect'
import { ToggleButton } from 'vue-js-toggle-button'
import moment from 'moment-timezone'

const vSelect = () => import('vue-multiselect')

const INITIAL_FORM_DATA = {
  company: {
    name: '',
    annualLeaveAllowance: 20,
    startDate: '',
  },
  maximumCarryOverInDays: 5,
  expiryPeriod: 3,
  expiryUnit: {
    key: 'months',
    name: 'Months',
  },
  hasCarryOver: false,
}

export default {
  name: 'CompanyForm',

  components: {
    SpinnerButton,
    SingleDatePicker,
    VSelect,
    ToggleButton,
    vSelect,
  },

  mixins: [FormatDate, ValidatesForm],

  props: {
    defaultCompanyName: {
      type: String,
      default: '',
    },

    submitButtonText: {
      type: String,
      default: 'Save',
    },

    pickStartDate: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: { ...INITIAL_FORM_DATA },
    }
  },

  computed: {
    dateRules() {
      const dateFormatOptionMapper = {
        dmy: 'dd/MM/yyyy',
        mdy: 'MM/dd/yyyy',
        ymd: 'yyyy/MM/dd',
      }

      return `required|date_format:${
        dateFormatOptionMapper[this.authUser.settings.date_format]
      }`
    },

    dateFormatWithSlash() {
      return this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash()
    },

    selectablePolicyExpiryUnits() {
      return [
        {
          key: 'days',
          name: 'Days',
        },
        {
          key: 'months',
          name: 'Months',
        },
        {
          key: 'never_expire',
          name: 'Never Expire',
        },
      ]
    },

    expiryPeriodErrorMessage() {
      if (this.form.expiryUnit.key === 'months') {
        return 'The expiry period must be between 1 and 12 months'
      }

      return 'The expiry period must be between 1 and 365 days'
    },

    expiryPeriodValidation() {
      if (this.form.expiryUnit.key === 'days') {
        return 'required|numeric|min_value:1|max_value:365'
      }
      if (this.form.expiryUnit.key === 'months') {
        return 'required|numeric|min_value:1|max_value:12'
      }

      return ''
    },
  },

  created() {
    this.form.company.name = this.defaultCompanyName

    this.form.company.startDate = moment()
      .startOf('year')
      .format(this.dateFormatWithSlash)
  },

  methods: {
    enableCarryOver({ value }) {
      this.form.hasCarryOver = value
    },

    async submit() {
      await this.validate()

      if (!this.valid) return

      this.$emit('submit', this.form, this.failCallback)
    },

    failCallback(response) {
      this.validateFromResponse(response)
    },

    reset() {
      this.$nextTick(() => {
        this.$validator.reset()
      })
    },
  },
}
</script>
