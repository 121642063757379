<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div data-cy="page-title"><h2 class="page-title">Companies</h2></div>
          <div>
            <SpinnerButton
              data-cy="btn-add-company"
              type="button"
              @click="create"
            >
              Add Company
            </SpinnerButton>
          </div>
        </div>
      </div>

      <div v-scroll-hint class="table-responsive">
        <table class="ld-table ld-table-clickable" data-cy="company-name-table">
          <thead>
            <tr>
              <th>Company Name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <template v-if="ownCompanies.length">
              <tr
                v-for="company in ownCompanies"
                :key="company.id"
                @click.stop="editCompanySettings(company)"
              >
                <td>{{ company.name }}</td>
                <td>
                  <div class="tw-flex tw-items-center tw-justify-end">
                    <button
                      class="btn btn-icon tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-ml-3"
                      title="Edit"
                      data-cy="edit-company"
                      @click.stop="editCompanySettings(company)"
                    >
                      <span class="tw-flex tw-items-center tw-justify-center">
                        <SvgIcon
                          name="edit-pencil"
                          class="svg-icon tw-w-3 tw-h-3 tw-text-gray-700"
                        />
                      </span>
                    </button>
                    <button
                      class="btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
                      title="Delete"
                      data-cy="delete-company"
                      @click.stop="destroy(company)"
                    >
                      <span class="tw-flex tw-items-center tw-justify-center">
                        <SvgIcon
                          name="trash"
                          class="svg-icon tw-w-3 tw-h-3 tw-text-white"
                        />
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="tw-bg-white" colspan="2" data-cy="no-company-data">
                <NoData message="You are not an admin of any company" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Modal
        :classes="[
          'tw-shadow-md',
          'tw-bg-white',
          'tw-rounded-lg',
          'modal-overflow-visible',
        ]"
        :max-width="480"
        name="company-form"
        width="95%"
        height="auto"
        adaptive
        scrollable
      >
        <div class="modal-header">
          <div class="tw-flex tw-justify-between">
            <div>
              <p class="modal-title">New Company</p>
            </div>
            <div>
              <button
                class="modal-close"
                data-cy="close-company"
                @click="$modal.hide('company-form')"
              >
                <SvgIcon name="close" class="tw-w-4 tw-h-4" />
              </button>
            </div>
          </div>
        </div>

        <CompanyForm
          :pick-start-date="true"
          :loading="loading"
          @submit="createCompany"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment-timezone'
import NoData from '@/components/NoData'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import CompaniesShortcuts from '@/mixins/page-shortcuts/CompaniesShortcuts'
import CompanyForm from '@/components/companies/CompanyForm'
import SpinnerButton from '@/components/SpinnerButton'
import { Company } from '@/api'

export default {
  name: 'Companies',

  middleware: 'auth',

  components: {
    SpinnerButton,
    NoData,
    CompanyForm,
  },

  mixins: [ValidatesForm, CompaniesShortcuts, FormatDate],

  data: () => ({
    loading: false,
    ownCompanies: [],
  }),

  computed: {
    ...mapGetters('auth', {
      allCompanies: ['companies'],
    }),

    dateFormatWithSlash() {
      return this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash()
    },
  },

  created() {
    this.fetchCompanies()
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    getStartDate(form) {
      const startDate =
        form.company.startDate instanceof moment
          ? form.company.startDate
          : moment.utc(form.company.startDate, this.dateFormatWithSlash)

      if (this.isFuture(startDate)) {
        return startDate.subtract(1, 'year').format('YYYY-MM-DD')
      }

      return startDate.format('YYYY-MM-DD')
    },

    isFuture(date) {
      if (date instanceof moment) {
        return date.isAfter(moment())
      }

      return moment(date).isAfter(moment())
    },

    async fetchCompanies() {
      try {
        const { data } = await Company.all()

        this.ownCompanies = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async createCompany(form, failCallback) {
      this.loading = true

      try {
        await Company.create({
          name: form.company.name,
          annual_leave_allowance: form.company.annualLeaveAllowance,
          has_carry_over: form.hasCarryOver,
          maximum_carry_over_in_days: form.maximumCarryOverInDays,
          expiry_period: form.expiryPeriod,
          expiry_unit: form.expiryUnit.key,
          start_date: this.getStartDate(form),
        })

        await this.fetchCompanies()

        this.fetchUser()

        this.success('Company saved successfully.')

        this.$modal.hide('company-form')
      } catch ({ response }) {
        failCallback(response)
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    create() {
      this.$modal.show('company-form')
    },

    editCompanySettings(company) {
      this.$router
        .push({ name: 'company', query: { company: company.id } })
        .catch(() => {})
    },

    async destroy(company) {
      const confirmed = await this.confirm(
        `Are you sure you want to delete ${company.name}?
         By clicking 'yes', a verification email will be sent to your email address`,
        'Confirm delete'
      )

      if (!confirmed) return

      await Company.sendDeleteVerificationEmail(company)

      this.success('Verification email sent successfully')
    },
  },
}
</script>
