<template>
  <div class="tw-p-6">
    <div class="tw-mb-4">
      <div>
        <HolidaysTable
          :holidays="holidays"
          :holiday-types="holidayTypes"
          @remove="removeHoliday"
        />
      </div>
    </div>

    <form class="tw-w-full" @submit.prevent="addHoliday">
      <div class="tw-w-full">
        <div class="tw-flex tw--mx-3">
          <div class="tw-w-full md:tw-w-1/3 tw-px-3">
            <div class="form-group">
              <label class="form-label" for="name"
                >Name <span class="required-field">&#42;</span></label
              >
              <input
                id="name"
                v-model="form.name"
                v-validate="'required'"
                name="name"
                data-vv-as="name"
                data-cy="extra-holiday-name"
                class="form-control"
                type="text"
                autocomplete="off"
              />
            </div>
          </div>

          <div class="tw-w-full md:tw-w-1/3 tw-px-3">
            <div class="form-group">
              <label class="form-label" for="date"
                >Date <span class="required-field">&#42;</span></label
              >
              <SingleDatePicker
                id="date"
                v-model="form.date"
                v-validate="'required'"
                :min-date="minDate"
                :max-date="maxDate"
                :placeholder="dateFormatWithSlash"
                :format="dateFormatWithSlash"
                parent-el="#holidays-form"
                data-vv-as="date"
                name="date"
                data-cy="date"
                class="form-control"
                autocomplete="off"
                type="text"
                readonly
              />
            </div>
          </div>

          <div class="tw-w-full md:tw-w-1/3 tw-px-3">
            <div class="form-group">
              <label class="form-label" for="type">Type</label>
              <vSelect
                id="type"
                v-model="holidayType"
                :options="holidayTypes"
                :multiple="false"
                :searchable="false"
                :show-labels="false"
                :allow-empty="false"
                :max-height="180"
                placeholder=""
                track-by="id"
                label="name"
                data-cy="select-holiday-type"
                name="type"
              >
              </vSelect>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-items-end tw-flex-col">
          <SpinnerButton
            :loading="isSaving"
            :disabled="!valid || isSaving"
            type="submit"
            :spinner-only="true"
            data-cy="add-extra-holiday"
          >
            <span class="tw-font-semibold tw-text-lg">&#43;</span> Add Holiday
          </SpinnerButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import HolidaysTable from '@/components/holidays/HolidaysTable'
import moment from 'moment-timezone'
import ValidateForm from '@/mixins/ValidatesForm'
import FormatDate from '@/mixins/FormatDate'
import SingleDatePicker from '@/components/SingleDatePicker'
import SpinnerButton from '@/components/SpinnerButton'
const vSelect = () => import('vue-multiselect')

const HOLIDAY_TYPES = [
  { id: 'non_working_day', name: 'Non-working Day' },
  { id: 'working_day', name: 'Working Day' },
]

export default {
  name: 'HolidaysForm',

  components: { SpinnerButton, SingleDatePicker, HolidaysTable, vSelect },

  mixins: [ValidateForm, FormatDate],

  props: {
    location: {
      type: Object,
      required: true,
    },

    year: {
      type: [Number, String],
      default: () => null,
    },

    isSaving: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedYear: this.year,
      form: {
        name: '',
        date: '',
        type: HOLIDAY_TYPES[0].id,
      },
    }
  },

  computed: {
    holidayTypes() {
      return HOLIDAY_TYPES
    },

    holidayType: {
      get() {
        return this.holidayTypes.find(type => type.id === this.form.type)
      },
      set(type) {
        this.form.type = type.id
      },
    },

    minDate() {
      if (!this.year) {
        return null
      }

      return moment(this.year + '-01-01')
    },

    maxDate() {
      if (!this.year) {
        return null
      }

      return moment(this.year + '-12-31')
    },

    holidays() {
      return this.location.holidaysForYear(this.selectedYear)
    },

    dateFormatWithSlash() {
      return this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash()
    },

    dateInMoment() {
      return moment(this.form.date, this.dateFormatWithSlash)
    },
  },

  methods: {
    addHoliday() {
      this.emitHoliday()

      this.reset()
    },

    emitHoliday() {
      this.$emit('add-holiday', {
        holiday: {
          name: this.form.name,
          type: this.form.type,
          date: this.dateInMoment,
        },
        location: this.location,
      })
    },

    reset() {
      this.form.name = ''
      this.form.date = ''
      this.form.type = HOLIDAY_TYPES[0].id
    },

    removeHoliday(holiday) {
      this.$emit('remove-holiday', holiday)
    },
  },
}
</script>
