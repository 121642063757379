<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div><h2 class="page-title">Maximum on Leave</h2></div>
          <div>
            <SpinnerButton
              data-cy="btn-add-limit"
              type="button"
              @click="create"
            >
              Add Limit
            </SpinnerButton>
          </div>
        </div>
      </div>

      <div v-scroll-hint class="table-responsive">
        <table class="ld-table ld-table-clickable">
          <thead>
            <tr>
              <th>Company or Department</th>
              <th>Maximum on Leave</th>
              <th>From Date (optional)</th>
              <th>To Date (optional)</th>
              <th style="width: 100px" />
            </tr>
          </thead>
          <tbody>
            <template v-if="leaveLimits.length">
              <LimitsTableRow
                v-for="leaveLimit in leaveLimits"
                :key="leaveLimit.id"
                :leave-limit="leaveLimit"
                @edit="edit(leaveLimit)"
                @destroy="destroy(leaveLimit)"
              />
            </template>
            <tr v-else>
              <td class="tw-bg-white" colspan="5"><no-data /></td>
            </tr>
          </tbody>
        </table>
      </div>

      <Modal
        :classes="[
          'tw-shadow-md',
          'tw-bg-white',
          'tw-rounded-lg',
          'modal-overflow-visible',
        ]"
        :max-width="480"
        name="leave-limit-form"
        width="95%"
        height="auto"
        adaptive
        scrollable
      >
        <div class="modal-header">
          <div class="tw-flex tw-justify-between">
            <div>
              <p class="modal-title">
                {{ editing ? 'Edit' : 'New' }} Leave Limit
              </p>
            </div>
            <div>
              <button
                class="modal-close"
                @click="$modal.hide('leave-limit-form')"
              >
                <SvgIcon name="close" class="tw-w-4 tw-h-4" />
              </button>
            </div>
          </div>
        </div>

        <div class="tw-mt-3 tw-p-3">
          <form class="tw-w-full" @submit.prevent="submit">
            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="company-or-department">
                  Company or Department
                  <span class="required-field">&#42;</span>
                </label>

                <DepartmentPicker
                  id="company-or-department"
                  v-model="form.department_id"
                  :options="selectableDepartments"
                  :reduce="option => option.id"
                  data-vv-name="company or department"
                />

                <p
                  v-show="errors.has('company-or-department')"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('company-or-department') }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="amount">
                  Maximum on Leave <span class="required-field">&#42;</span>
                </label>

                <input
                  id="amount"
                  v-model="form.amount"
                  v-validate="'required|integer'"
                  v-focus
                  data-vv-name="amount"
                  class="form-control"
                  type="number"
                  min="0"
                  step="1"
                  data-cy="limit-amount"
                  placeholder="Amount (Leave)"
                />

                <p
                  v-show="errors.has('amount')"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('amount') }}
                </p>
              </div>
            </div>

            <div
              class="md:tw-flex md:tw-justify-between md:tw-space-x-4 tw-mt-4 tw-px-3"
            >
              <div class="form-group tw-flex-1">
                <label class="form-label" for="from">
                  From
                </label>

                <SingleDatePicker
                  id="from"
                  v-model="form.from"
                  v-validate:from="dateRules"
                  :format="dateFormatWithSlash"
                  :placeholder="dateFormatWithSlash"
                  data-vv-as="from"
                  name="from"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                  data-cy="from"
                />
                <p
                  v-show="errors.has('from')"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('from') }}
                </p>
              </div>

              <div class="form-group tw-flex-1">
                <label class="form-label" for="to">
                  To
                </label>

                <SingleDatePicker
                  id="to"
                  v-model="form.to"
                  v-validate:to="dateRules"
                  :format="dateFormatWithSlash"
                  :placeholder="dateFormatWithSlash"
                  data-vv-as="to"
                  name="to"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                  data-cy="to"
                />
                <p
                  v-show="errors.has('to')"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('to') }}
                </p>
              </div>
            </div>

            <div class="tw-flex tw-flex-wrap tw-mb-3">
              <div class="tw-w-full tw-px-3">
                <div class="tw-flex tw-justify-end">
                  <button
                    v-if="!editing"
                    class="btn btn-borderless btn-link"
                    type="button"
                    @click="reset"
                  >
                    Clear
                  </button>

                  <SpinnerButton
                    :disabled="!valid || loading"
                    :loading="loading"
                    :spinner-only="true"
                    type="submit"
                    data-cy="submit-limit"
                  >
                    Save
                  </SpinnerButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import ValidatesForm from '@/mixins/ValidatesForm'
import NoData from '@/components/NoData'
import DepartmentPicker from '@/components/pickers/DepartmentPicker'
import SpinnerButton from '@/components/SpinnerButton'
import { sortBy } from 'lodash-es'
import FormatDate from '@/mixins/FormatDate'
import moment from 'moment-timezone'
import LimitsTableRow from '@/components/limits/LimitsTableRow'
import LimitsShortcuts from '@/mixins/page-shortcuts/settings/LimitsShortcuts'
import { Departments, Limits } from '@/api'

const SingleDatePicker = () => import('@/components/SingleDatePicker')

export default {
  name: 'Limits',

  components: {
    LimitsTableRow,
    NoData,
    SingleDatePicker,
    DepartmentPicker,
    SpinnerButton,
  },

  mixins: [ValidatesForm, FormatDate, LimitsShortcuts],

  data: () => ({
    leaveLimits: [],
    departments: [],
    editing: false,
    loading: false,
    employments: [],
    selectedDepartment: '',
    form: {
      id: null,
      from: '',
      to: '',
      department_id: null,
      amount: 0,
      can_request_leave_when_limit_exceeds: true,
    },
  }),

  computed: {
    selectableDepartments() {
      return [
        {
          id: null,
          name: 'Company',
        },
        ...sortBy(this.departments, 'name'),
      ]
    },

    dateFormatWithSlash() {
      return this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash()
    },

    dateRules() {
      const dateFormatOptionMapper = {
        dmy: 'dd/MM/yyyy',
        mdy: 'MM/dd/yyyy',
        ymd: 'yyyy/MM/dd',
      }

      return `required|date_format:${
        dateFormatOptionMapper[this.authUser.settings.date_format]
      }`
    },

    from() {
      if (this.form.from instanceof moment) {
        return this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
          this.form.from
        )
      }
      return this.form.from
    },

    to() {
      if (this.form.to instanceof moment) {
        return this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
          this.form.to
        )
      }
      return this.form.to
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler: 'fetch',
    },
  },

  methods: {
    async fetch(newVal, oldVal) {
      if (newVal === oldVal) return

      this.fetchLeaveLimits()
      this.fetchDepartments()
    },

    async fetchLeaveLimits() {
      try {
        const { data } = await Limits.get(this.activeCompany.id)

        this.leaveLimits = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchDepartments() {
      try {
        const { data } = await Departments.all(this.$route.query)

        this.departments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    create() {
      this.reset()

      this.editing = false

      this.$modal.show('leave-limit-form')
    },

    edit(leaveLimit) {
      this.editing = true

      this.fillLeaveLimitForm(leaveLimit)

      this.$modal.show('leave-limit-form')
    },

    fillLeaveLimitForm(leaveLimit) {
      this.form = {
        ...leaveLimit,
        from: leaveLimit.from
          ? this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
              leaveLimit.from,
              'utc'
            )
          : '',
        to: leaveLimit.to
          ? this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
              leaveLimit.to,
              'utc'
            )
          : '',
      }
    },

    async submit() {
      await this.validate()

      if (!this.valid) return

      try {
        this.loading = true

        let from = this.form.from
          ? moment(this.form.from, this.dateFormatWithSlash)
              .utc(true)
              .format('YYYY-MM-DD')
          : null

        let to = this.form.to
          ? moment(this.form.to, this.dateFormatWithSlash)
              .utc(true)
              .format('YYYY-MM-DD')
          : null

        const params = {
          company_id: this.activeCompany.id,
          department_id: this.form.department_id,
          amount: this.form.amount,
          can_request_leave_when_limit_exceeds: this.form
            .can_request_leave_when_limit_exceeds,
          from,
          to,
        }

        this.editing
          ? await Limits.update(this.form.id, params)
          : await Limits.create(params)

        await this.fetchLeaveLimits()

        this.success('Leave Limit saved successfully.')

        this.$modal.hide('leave-limit-form')

        this.reset()
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },

    async destroy(leaveLimit) {
      const confirmed = await this.confirm(
        'Are you sure you want to delete?',
        'Confirm delete'
      )

      if (!confirmed) return

      try {
        await Limits.delete(leaveLimit)

        await this.fetchLeaveLimits()

        this.success('Leave Limit deleted successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    reset() {
      this.$nextTick(() => {
        this.form = {
          id: null,
          from: '',
          to: '',
          department_id: null,
          can_request_leave_when_limit_exceeds: true,
          amount: 0,
        }

        this.loading = false
        this.editing = false
      })
    },
  },
}
</script>
